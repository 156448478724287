import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { Bill3rdPartyEInvoiceLayout } from '@ifca-root/react-component/src/components/Bill3rdPartyEInvoiceLayout/Bill3rdPartyEInvoiceLayout';

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Background from 'assets/images/HR-Background.jpg';
import Logo from 'assets/images/MenuX-Logo.svg';
import { useGetBillAndOutletForNotAuthQuery } from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import theme from '@ifca-root/react-component/src/assets/theme';
import {
	Button,
	Card,
	CardContent,
	Grid,
	MenuItem,
	TextField,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { formatDate } from 'helpers/formatDate';
import SnackBarContext from 'containers/App/Store/SnackBarContext';

interface BillUserEInvoiceFormProps {
	name: string;
	email: string;
	contactNo: string;
	address: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
	identityType: string;
	identityNumber: string;
	tin: string;
	sstRegNo: string;
}
export const EInvoiceQR = (props: any) => {
	let history = useHistory();
	let location = useLocation();
	const { mode } = props;
	const menuData: any = location?.state;
	const editData = menuData?.company as any;

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const appCode: any = localStorage.getItem('appCode');

	const { outletID, billID } = useParams<any>(); // Keeping it in case you need these later
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		called: billAndOutletForNotAuthCalled,
		loading: billAndOutletForNotAuthLoading,
		error: billAndOutletForNotAuthError,
		data: { getBillAndOutletForNotAuth } = { getBillAndOutletForNotAuth: [] },
	} = useGetBillAndOutletForNotAuthQuery({
		variables: { outletID: outletID, billID: billID },
		fetchPolicy: 'network-only',
		onCompleted: ({ getBillAndOutletForNotAuth }) => {
			if (getBillAndOutletForNotAuth[0]?.einvoice_qr_url !== null) {
				window.location.href = getBillAndOutletForNotAuth[0]?.einvoice_qr_url;
			}
		},
	});

	const sumPaymentAmount = payments => {
		let totalT = 0;
		payments?.map(x => {
			totalT += x?.receivedAmount - x?.changeAmount;
		});
		return totalT;
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	return (
		<>
			{billAndOutletForNotAuthLoading && <Loading />}

			<AuthLayout
				logo={
					getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
					getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
						? null
						: Logo
				}
				image={Background}
				className="margin-override"
			>
				<br></br>

				<CardContents
					style={{
						overflow: 'auto', // Enable vertical scrolling
						// overflowX: 'hidden',
						maxHeight:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? 'calc(115vh - 170px)'
								: 'calc(100vh - 170px)', // Set a max height for the content area
						paddingRight: '10px', // Optional: to prevent horizontal scroll when content is too wide
						willChange: 'transform', // Hint to the browser for scrolling behavior
						marginTop:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? '-40px'
								: '0px',
						marginBottom:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? '-10px'
								: '0px',
					}}
				>
					{getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
					getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl ? (
						<Grid container>
							<Grid item xs={12} style={{ textAlign: 'center' }}>
								{getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ? (
									<img
										className="icon-svg"
										style={{
											width: '200px',
											// marginTop: '20px',
											marginBottom: '10px',
											height: '100px',
										}}
										src={getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL}
										// crossOrigin="anonymous"
									/>
								) : (
									<img
										className="icon-svg"
										style={{
											height: '90px',
											width: '150px',
											// marginTop: '20px',
											marginBottom: '5px',
										}}
										src={getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL}
									/>
								)}
							</Grid>
						</Grid>
					) : null}

					<Grid container>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<span
								style={{
									fontFamily: 'Poppins, sans-serif',
									fontWeight: 700,
									fontSize: '24px',
									textAlign: 'center',
								}}
							>
								e-Invoice Request
							</span>
						</Grid>
					</Grid>
					<Card
						style={{
							backgroundColor: '#f0f0f0',
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Adds a shadow (MUI built-in shadow scale 0-24)
							borderRadius: '8px', // Optional: Adds rounded corners
							width: isDesktop ? '60%' : '100%', // Full width
							// justifyContent: 'center',
							// alignContent: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							marginLeft: isDesktop ? '18%' : '0%',
							marginTop: '4%',
							marginBottom: '4%',
						}}
					>
						<CardContent>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Document No
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{getBillAndOutletForNotAuth[0]?.billNo}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Document Date
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{formatDate(getBillAndOutletForNotAuth[0]?.createdTs)}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Currency
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									{' '}
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{getBillAndOutletForNotAuth[0]?.outlet?.currencyCode}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Amount
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{sumPaymentAmount(
											getBillAndOutletForNotAuth[0]?.payments,
										).toFixed(2)}
									</div>
								</Grid>
							</Grid>
							{getBillAndOutletForNotAuth[0]?.einvoice_status === 'RECORDED' &&
							getBillAndOutletForNotAuth[0]?.einvoice_irbm_uniqueID === null ? (
								<Grid container>
									<Grid item xs={6} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											Status
										</div>
									</Grid>
									<Grid item xs={1} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											:
										</div>
									</Grid>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											In Progress
										</div>
									</Grid>
								</Grid>
							) : (
								<></>
							)}
						</CardContent>
					</Card>
				</CardContents>
			</AuthLayout>
		</>
	);
};
