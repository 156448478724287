// import theme from '@ifca-root/react-component/src/assets/theme';
import {
	Avatar,
	IconButton,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TakeawayIcon from 'assets/icons/takeaway_footer_color.svg';
import AppContext from 'containers/App/Store/AppContext';
import { PosPermission, useGetUserLogoQuery } from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/hooks/useBroadcastChannel';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
	AccountJournalsIcon,
	BillListingIcon,
	BusinessInsightsIcon,
	GeneralSettingsIcon,
	HomeIcon,
	LogoutIcon,
	OutletSettingsIcon,
	SideMenuHomeIcon,
	UtilityIcon,
} from '../../assets';
import UserProfile from '../../assets/icons/user-profile.svg';
import io from 'socket.io-client';
import { SystemMsgs } from 'helpers/SystemMsg';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { menuxSocketUrl } from 'POSClient';
import { SocketContext } from 'containers/Socket/SocketContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			[theme.breakpoints.up('sm')]: {
				// width: '240px',
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		drawerPaper: {
			// width: '240px',
			width: drawerWidth,
			[theme.breakpoints.up('sm')]: {
				top: 64,
				height: 'calc(100% - 64px)',
			},
			zIndex: 100,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		activeListItem: {
			borderLeft: '4px solid red',
			boxShadow: '0 3px 2px -3px gray',
			backgroundColor: '#ff9800',
			'& $listItemText': {
				color: 'white',
				marginTop: 0,
				marginBottom: 0,
				fontWeight: 500,
			},
			'& $listItemIcon': {
				color: 'white',
				marginLeft: '-4px',
				minWidth: '36px',
			},
		},
		listItemText: {
			fontSize: '1rem',
			fontWeight: 400,
			color: 'black',
			marginTop: 0,
			marginBottom: 0,
		},
	}),
);

interface Props {
	window?: () => Window;
	open?: boolean;
	variant?: any;
	onCloseDrawer?: any;
}

// export interface LayoutDrawerNavs {
// 	name: string;
// 	path: string;
// 	icon: string;
// 	iconActive: string;
// }

// export interface LayoutDrawerProps extends DrawerProps {
// 	/**
// 	 * Injected by the documentation to work in an iframe.
// 	 * You won't need it on your project.
// 	 */
// 	window?: () => Window;
// 	activeIndex?: number;
// 	onCloseDrawer?: any;
// }

export const LayoutDrawer = (props: Props) => {
	// const { navs = [], ...rest } = props;
	const { window, open, onCloseDrawer, variant, ...rest } = props;
	const classes = useStyles();
	const outletID = localStorage.getItem('latestOutlet');
	const container =
		window !== undefined ? () => window().document.body : undefined;
	let history = useHistory();
	const { globalState, dispatch }: any = useContext(AppContext as any);
	// const [user] = useAtom(userAtom); sorry george don't have time to learn this yet
	let location = useLocation();
	const userData: any = location?.state;
	const user = JSON.parse(localStorage.getItem('loggedInUser')) ?? userData;
	const outlet: any = JSON.parse(localStorage.getItem('Outlet'));
	const eInvoice = outlet?.company?.isEInvIntegrated || false;
	const { signOut } = useBroadcastChannel();

	const isIn = (route: string, module: string) => {
		return route.match(module);
	};

	const { handlePermHidden } = usePermissionChecker();

	const pathName: any = history?.location?.pathname;

	let isHome = false,
		isKitchenApp = false,
		isOutletApp = false,
		isCustomerApp = false;

	if (
		isIn(pathName, '/') ||
		isIn(pathName, '/home') ||
		isIn(pathName, '/alternate')
	) {
		isHome = true;
	}

	if (isIn(pathName, 'kitchen/home')) {
		isKitchenApp = true;
	}
	if (isIn(pathName, 'outlet-app')) {
		isOutletApp = true;
	}
	if (isIn(pathName, 'patron-app')) {
		isCustomerApp = true;
	}

	const { data: pic } = useGetUserLogoQuery({
		variables: {
			refID: user?.ID,
		},
	});

	// const { setOpenSnackBar, setSnackBarMsg } = useContext(
	// 	SnackBarContext as any,
	// );

	// const [socket, setSocket] = useState(null);

	// useEffect(() => {
	// 	const newSocket = io('http://localhost:9000');

	// 	setSocket(newSocket);

	// 	// Cleanup function
	// 	return () => {
	// 	  newSocket.disconnect();
	// 	};
	//   }, []);

	//   console.log(socket, "socket")

	//   useEffect(() => {

	// 	if (socket) {

	// 	  socket.on('notification', (notification) => {
	// 		console.log("apa")
	// 		// HotelNotification({
	// 		//   variables: {
	// 		// 	NotificationType: 'All',
	// 		// 	HotelID: Hotel,
	// 		// 	UserID: LoggedInUser?.ID,
	// 		//   }
	// 		// })
	// 		console.log(notification,'notification')
	// 		// setOpenSnackBar(true);
	// 		// setSnackBarMsg(SystemMsgs.autoDayEndTime());
	// 	  });
	// 	}

	//   }, [socket])

	const { socket }: any = useContext(SocketContext);
	const navigations = isOutletApp
		? [
				{
					name: 'Home',
					path: `/menu/outlet-app/${outletID}/table/grid`,
					icon: SideMenuHomeIcon,
					iconActive: HomeIcon,
					permission: PosPermission.HomeTableLayoutView,
				},
				{
					name: 'Takeaway',
					path: `/menu/outlet-app/${outletID}/takeaway`,
					icon: TakeawayIcon,
					iconActive: TakeawayIcon,
					permission: PosPermission.TakeawayShow,
				},
				{
					name: 'Common Settings',
					path: `/menu/outlet-app/common-setting`,
					icon: GeneralSettingsIcon,
					iconActive: GeneralSettingsIcon,
					permission: PosPermission.GeneralSettingShow,
				},
				{
					name: 'General Settings',
					path: `/menu/outlet-app/general-setting`,
					icon: GeneralSettingsIcon,
					iconActive: GeneralSettingsIcon,
					permission: PosPermission.GeneralSettingShow,
				},
				{
					name: 'Outlet Settings',
					path: `/menu/outlet-app/outlet-setting`,
					icon: OutletSettingsIcon,
					iconActive: OutletSettingsIcon,
					permission: PosPermission.OutletSettingShow,
				},
				{
					name: 'Bill Listing',
					path: `/menu/outlet-app/${outletID}/bill-listing`,
					icon: BillListingIcon,
					iconActive: BillListingIcon,
					permission: PosPermission.BillListingShow,
				},
				// {
				// 	name: 'Reservations',
				// 	path: `/menu/outlet-app/${outletID}/reservation`,
				// 	icon: ReservationTableIcon,
				// 	iconActive: ReservationTableIcon,
				// 	permission: PosPermission.ReservationShow,
				// },
				// {
				// 	name: 'Online Orders',
				// 	path: `/menu/outlet-app/online-order`,
				// 	icon: OnlineOrdersIcon,
				// 	iconActive: OnlineOrdersIcon,
				// },
				{
					name: 'Utility',
					path: `/menu/outlet-app/utility`,
					icon: UtilityIcon,
					iconActive: UtilityIcon,
					permission: PosPermission.UtilityShow,
				},
				{
					name: 'Business Analytics',
					path: `/menu/outlet-app/business-insight/business-analytic`,
					icon: BusinessInsightsIcon,
					iconActive: BusinessInsightsIcon,
					permission: PosPermission.BizAnalyticsShow,
				},
				{
					name: `Digital Reporting`,
					path: `/menu/outlet-app/${outletID}/digital-reporting`,
					icon: BusinessInsightsIcon,
					iconActive: BusinessInsightsIcon,
					//label1: `Booking, Transaction, Manager Report`,
					permission: PosPermission.DigitalReportingShow,
				},
				{
					path: `/menu/outlet-app/${outletID}/digital-bill`,
					name: `Digital Document`,
					icon: BusinessInsightsIcon,
					iconActive: BusinessInsightsIcon,
					//label1: `Digital Bill`,
					permission: PosPermission.DigitalDocumentShow,
				},
				{
					name: 'Account Journals',
					path: `/menu/outlet-app/account-journals`,
					icon: AccountJournalsIcon,
					iconActive: AccountJournalsIcon,
					permission: PosPermission.AccountJournalsShow,
				},
				// Conditionally include the eInvoice item if eInvoice is true
				...(eInvoice === true
					? [
							{
								name: 'Consolidated e-Invoice',
								path: `/menu/outlet-app/${outletID}/consolidate-e-inovoice`,
								icon: AccountJournalsIcon,
								iconActive: AccountJournalsIcon,
								permission: PosPermission.ConsolidatedEInvoiceShow,
							},
					  ]
					: []),
				{
					name: 'Logout',
					path: `/logout`,
					icon: LogoutIcon,
					iconActive: LogoutIcon,
					permission: undefined,
				},
		  ]
		: isKitchenApp
		? [
				{
					name: 'Logout',
					path: `/logout`,
					icon: LogoutIcon,
					iconActive: LogoutIcon,
					permission: undefined,
				},
		  ]
		: [
				{
					name: 'Logout',
					path: `/logout`,
					icon: LogoutIcon,
					iconActive: LogoutIcon,
					permission: undefined,
				},
		  ];

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRedirectProfile = () => {
		if (!!onCloseDrawer) {
			onCloseDrawer();
		}
		setAnchorEl(null);
		history.push({
			pathname: '/profile',
		});

		localStorage.setItem('prevPath', location.pathname);
	};

	const drawer = (
		<div className="route-container">
			<List disablePadding>
				{navigations?.map((v, i) => {
					if (
						handlePermHidden({
							outletID: outletID,
							permEnum: v.permission,
						})
					)
						return (
							<ListItem
								autoFocus={false}
								button
								key={i}
								dense
								activeClassName={'active'}
								divider
								component={NavLink}
								selected={false}
								to={v?.path}
								onClick={() => {
									if (!!onCloseDrawer) {
										onCloseDrawer();
									}
									if (v?.name === 'Sign Out') {
										signOut(user?.ID);
										sessionStorage.clear();
										sessionStorage.removeItem('key');
									}
								}}
							>
								<ListItemIcon>
									<img className="icon-svg" src={v?.icon} alt="drawer-icon" />
									{/* <img
									className="icon-svg"
									src={v?.iconActive}
									alt="drawer-icon"
								/> */}
								</ListItemIcon>
								<ListItemText primary={v?.name} />
							</ListItem>
						);
				})}
			</List>
		</div>
	);

	// User Profile Wrapper
	const userProfile = (
		<div className="profile-container">
			<div className="profile-avatar">
				{/* <Avatar alt="avatar" className="avatar" /> */}
				{pic?.logoView?.fileURL ? (
					<img src={pic?.logoView?.fileURL} alt="" className="avatar" />
				) : (
					<Avatar alt="avatar" className="avatar" />
				)}
			</div>
			<List className="profile-list" disablePadding>
				<ListItem>
					<ListItemText
						primary={
							<span
								className=""
								style={{
									fontWeight: 700,
									fontSize: '14px',
									fontFamily:
										['Poppins', 'sans-serif'].join(',') + ' !important',
								}}
							>
								{user?.name}
							</span>
						}
						secondary={
							<span
								className=""
								style={{
									fontSize: '10px',
									fontFamily:
										['Poppins', 'sans-serif'].join(',') + ' !important',
								}}
							>
								{user?.email}
							</span>
						}
					/>
					<ListItemSecondaryAction>
						<IconButton
							edge="end"
							aria-label="simple-menu"
							onClick={handleProfileClick}
						>
							<ExpandMore />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className="drawer-submenu"
			>
				<MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
					<img className="icon-dropdown-svg" src={UserProfile} alt="" /> Profile
				</MenuItem>
				<MenuItem
					className="drawer-dropdown"
					onClick={() => {
						signOut(user?.ID);
						sessionStorage.clear();
						sessionStorage.removeItem('key');
						history.push('/logout');
					}}
				>
					<img className="icon-dropdown-svg" src={LogoutIcon} alt="" /> Logout
				</MenuItem>
			</Menu>
		</div>
	);

	return (
		<nav aria-label="main menu" hidden={!open}>
			<Drawer
				{...rest}
				container={container}
				variant={variant}
				anchor={'left'}
				open={open}
				onClose={onCloseDrawer}
				className="drawer"
				classes={{
					paper: classes.drawerPaper,
				}}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				{userProfile}
				{drawer}
			</Drawer>
		</nav>
	);
};
