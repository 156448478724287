import { useLazyQuery } from '@apollo/react-hooks';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Badge, Grid, Paper } from '@material-ui/core';
import { KitchenAppIcon, OutletAppIcon, WebsiteAppNewIcon } from 'assets';
import {
	COUNTRY,
	GET_ACCOUNT_DETAIL,
	GET_CURRENCY,
	GET_PACKAGE_BY_ACCOUNT,
	GET_SUBSCRIPTION_ENTITY_CONTROL,
} from 'containers/AccountModule/AccountQuery';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetHomeSummaryQuery,
	useGetOutletLazyQuery,
	useGetUserLogoLazyQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import localForage from 'localforage';
import { AccountClient } from 'ProductsClient';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const HomeIcon = () => {
	useEffect(() => {
		console.log('HomeIcon');
	}, []);
	let history = useHistory();
	let location = useLocation();
	const [outletID, setOutletID] = useState(null);

	const { globalState, dispatch } = useContext(AppContext as any);
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const [
		loadOutlet,
		{
			called: outletCalled,
			loading: outletLoading,
			error: outletError,
			data: { getOutlet } = { getOutlet: [] },
		},
	] = useGetOutletLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOutlet }) => {
			localStorage.setItem(
				'latestOutlet',
				loggedInUserProfile?.lastestAccessedEntity ?? getOutlet[0]?.ID,
			);
			setOutletID(
				loggedInUserProfile?.lastestAccessedEntity ?? getOutlet[0]?.ID,
			);

			const filteredOutlets: any =
				getOutlet?.filter(
					x =>
						x?.ID ===
						(loggedInUserProfile?.lastestAccessedEntity ?? getOutlet[0]?.ID),
				)[0] ?? {};

			localStorage.setItem('Outlet', JSON.stringify(filteredOutlets));

			// loadAllMealPeriod();
		},
	});

	// const currTs = new Date();
	// const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();

	// const allRange = [];
	// for (var i = 0; i <= 1440; i++) {
	// 	allRange.push(i);
	// }

	// const getTimeRange = range => {
	// 	if (range.startTime < range.endTime) {
	// 		let timeRange = [];
	// 		for (var i = range.startTime; i <= range.endTime; i++) {
	// 			timeRange.push(i);
	// 		}
	// 		return timeRange;
	// 	} else {
	// 		let timeRange = [];
	// 		for (var i = range.endTime + 1; i <= range.startTime; i++) {
	// 			timeRange.push(i);
	// 		}
	// 		return allRange.filter(val => !timeRange.includes(val));
	// 	}
	// };

	// const [
	// 	loadAllMealPeriod,
	// 	{
	// 		called: allMealPeriodCalled,
	// 		loading: allMealPeriodLoading,
	// 		data: { getAllMealPeriod } = { getAllMealPeriod: [] },
	// 	},
	// ] = useGetAllMealPeriodLazyQuery({
	// 	onCompleted: ({ getAllMealPeriod }) => {
	// 		localStorage.setItem('allMealPeriod', JSON.stringify(getAllMealPeriod));
	// 		var outletIDs = Object.keys(getAllMealPeriod);
	// 		let currentMealPeriod = {};
	// 		outletIDs?.map(outletID => {
	// 			let currMealPeriod = getAllMealPeriod[outletID]?.filter(mp => {
	// 				const formattedExMp = {
	// 					startTime:
	// 						Number(mp?.startTime.split(':')[0]) * 60 +
	// 						Number(mp?.startTime.split(':')[1]),
	// 					endTime:
	// 						Number(mp?.endTime.split(':')[0]) * 60 +
	// 						Number(mp?.endTime.split(':')[1]),
	// 				};
	// 				//find if existing mp overlap with current one
	// 				if (getTimeRange(formattedExMp).includes(currTimeInt)) {
	// 					return mp;
	// 				}
	// 				// mealPeriod =>
	// 				// 	mealPeriod.startTime < currTime && mealPeriod.endTime > currTime,
	// 			});
	// 			currentMealPeriod[outletID] = currMealPeriod;
	// 		});
	// 		localStorage.setItem(
	// 			'currentMealPeriod',
	// 			JSON.stringify(currentMealPeriod),
	// 		);
	// 	},
	// });

	const {
		loading,
		error,
		data: { loggedInUserProfile, getRolePermission, getPOSPermission } = {
			loggedInUserProfile: null,
			getRolePermission: [],
			getPOSPermission: [],
		},
	} = useGetHomeSummaryQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			loadOutlet();
		},
	});

	const [
		loadAccountInfos,
		{
			loading: accountLoading,
			error: accountError,
			data: { getAccountDetail } = {
				getAccountDetail: {},
			},
		},
	] = useLazyQuery(GET_ACCOUNT_DETAIL, {
		client: AccountClient,
	});

	const [
		loadSubscriptionControlInfo,
		{
			loading: subscriptionControlLoading,
			error: subscriptionControlError,
			data: { subscribedUserAndEntityCount } = {
				subscribedUserAndEntityCount: {},
			},
		},
	] = useLazyQuery(GET_SUBSCRIPTION_ENTITY_CONTROL, {
		client: AccountClient,
	});

	const [
		loadPackage,
		{
			loading: packageLoading,
			error: packageError,
			data: { getPackageByAccount } = { getPackageByAccount: [] },
		},
	] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
		client: AccountClient,
	});

	const [
		loadCurrency,
		{
			loading: currencyLoading,
			error: currencyError,
			data: { getCurrency } = { getCurrency: [] },
		},
	] = useLazyQuery(GET_CURRENCY, {
		client: AccountClient,
	});

	const [
		loadCountry,
		{
			loading: countryLoading,
			error: countryError,
			data: { getCountry } = { getCountry: [] },
		},
	] = useLazyQuery(COUNTRY, {
		client: AccountClient,
	});

	const [loadPic, { data: pic }] = useGetUserLogoLazyQuery({});

	useEffect(() => {
		if (loggedInUserProfile?.ID) {
			loadAccountInfos({
				variables: {
					accountID: loggedInUserProfile?.accountID,
				},
			});
			loadSubscriptionControlInfo({
				variables: {
					softwareCode: 'MENU',
					accountID: loggedInUserProfile?.accountID,
				},
			});
			loadPackage({
				variables: {
					softwareCode: 'MENU',
					accountID: loggedInUserProfile?.accountID,
				},
			});
		}
		if (loggedInUserProfile?.ID) {
			loadPic({ variables: { refID: loggedInUserProfile?.ID } });
		}
	}, [loggedInUserProfile]);

	useEffect(() => {
		if (getAccountDetail?.currencyID) {
			loadCurrency({
				variables: {
					ID: getAccountDetail?.currencyID,
				},
			});
		}
		if (getAccountDetail?.address) {
			loadCountry({
				variables: {
					name: getAccountDetail?.address?.country ?? 'Malaysia',
				},
			});
		}
	}, [getAccountDetail]);

	let userAccDetail = {
		...getAccountDetail!,
		...loggedInUserProfile!,
		companyName: getAccountDetail?.name,
		companyEmail: getAccountDetail?.email,
		contactNo: getAccountDetail?.contactNo,
		companyCurrency: getCurrency[0]?.name,
		companyCurrencyCode: getCurrency[0]?.code,
		companyCountryCode: getCountry[0]?.code?.toLowerCase(),
		mobileNo: loggedInUserProfile?.contactNo!,
		maxOutlet: subscribedUserAndEntityCount?.entityControl?.noOfEntity,
		imageURL: pic?.logoView?.fileURL,
	};

	// USER INFO
	localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));

	// INDIVIDUAL PERMISSION
	localForage.setItem('permission', getRolePermission);
	localForage.setItem('POSPermission', getPOSPermission);

	const { permission } = usePermissionChecker();

	let outletRoute;
	if (getOutlet?.length === 0) {
		outletRoute = `/menu/outlet-app/outlet-setting`;
	} else {
		outletRoute = `/menu/outlet-app/${outletID}/table/grid`;
	}
	const mainMenuList = [
		{
			path: `${outletRoute}`,
			onClick: () => window.open(`${outletRoute}`),
			reroute: false,
			reroutePath: '',
			icon: OutletAppIcon,
			deployed: true,
			title: `Restaurant`,
			// label1: `Active Account:`,
			// data1: activeSupplierCount || 0,
			permission: PosPermission.RestaurantIcon,
			package_name: 'OUTLET',
		},
		{
			path: `/kitchen/login`,
			onClick: () => window.open(`/kitchen/login`),
			reroute: false,
			reroutePath: '',
			icon: KitchenAppIcon,
			deployed: true,
			title: `Kitchen`,
			// label1: `Last Expense on`,
			// data1: formatDate(lastMiscExpDate) || 'N/A',
			permission: PosPermission.KitchenIcon,
			package_name: 'KITCHEN',
		},
		{
			path: `/printer-server/login`,
			onClick: () => window.open(`/printer-server/login`),
			reroute: false,
			reroutePath: '',
			icon: WebsiteAppNewIcon,
			deployed: true,
			title: `Printer Server`,
			// label1: `Last Expense on`,
			// data1: formatDate(lastMiscExpDate) || 'N/A',
			permission: PosPermission.PrinterServerIcon,
			package_name: 'KITCHEN',
		},
	];

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	let package_permission;
	if (!packageLoading) {
		package_permission = mainMenuList.filter((v, i) => {
			return i == 0 ? v : getPackageByAccount?.includes(v?.package_name);
		});
	}

	return (
		<>
			{loading && <Loading />}
			{accountLoading && <Loading />}
			{packageLoading && <Loading />}
			<MainHeader
				mainBtn="menu"
				onClick={handleDrawer}
				smTitle="MenuX"
				title={user?.companyName}
				routeSegments={[
					{
						name: 'Integrated Restaurant Management Solution',
						current: true,
					},
				]}
			/>
			<ContentWrapper>
				<Grid container className="card-function mobile-app-grid">
					{/* {mainMenuList?.map((el, index) => {
						return (
							<Grid key={index} item xs={4} lg={3}>
								<Paper
									className="mobile-app"
									onClick={el?.onClick}
									// onClick={() => {
									// 	el?.reroute
									// 		? navigateReactUrl(el?.reroutePath, el?.path)
									// 		: window.open(el?.path, el?.path);
									// }}
								>
									<Badge color="primary">
										<img style={{ width: '55px' }} src={el.icon} alt="svg" />
									</Badge>
								</Paper>
								<div className="xxTitle mobile-app-label">{el.title}</div>
							</Grid>
						);
					})} */}
					{package_permission?.map((el, index) => {
						if (
							el?.deployed &&
							(loggedInUserProfile?.superUser ||
								getRolePermission?.hasOwnProperty(el?.permission))
						)
							return (
								<Grid key={index} item xs={6} lg={3}>
									<Paper
										className="mobile-app"
										onClick={() => {
											window.open(el?.path, el?.path);
										}}
									>
										<Badge badgeContent={el.badgeNoti} color="primary">
											<img src={el.icon} alt="svg" />
										</Badge>
									</Paper>
									<div className="xxTitle mobile-app-label">{el.title}</div>
								</Grid>
							);
					})}
				</Grid>
			</ContentWrapper>
		</>
	);
};
