import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	Input,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetDailyMenuItemAvailabilityDocument,
	GetMenuItemDocument,
	useAttachmentListingLazyQuery,
	useCreateMenuItemWithOptionsMutation,
	useGetFamilyLazyQuery,
	useGetKitchenPrinterQuery,
	useGetMajorQuery,
	useGetMenuItemLazyQuery,
	useGetOutletQuery,
	useGetTaxSchemeListingQuery,
	useGetUomQuery,
	useUpdateMenuItemWithOptionsMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { MenuItemOptionsForm } from './MenuItemOptionsForm';
import { Autocomplete } from '@material-ui/lab';

interface MenuItemForm {
	accountID: string;
	name: string;
	nativeName: string;
	kitchenName: string;
	description: string;
	familyID: string;
	majorID: string;
	kitchenPrinterID: string;
	kitchenPrinter2ID: string;
	taxSchemeID: string;
	outletID: string;
	menuID: string;
	isActive: boolean;
	isOpenItem: boolean;
	isOpenPrice: boolean;
	isChefRecommended: boolean;
	isQuantityControlled: boolean;
	isServiceChargeable: boolean;
	defaultQuantity: number;
	isTaxable: boolean;
	isDiscountable: boolean;
	isSetMenu: boolean;
	isByWeight: boolean;
	uomID: string;
	option: any;
	standardCost: number
}

export const MenuItemForm = (props: any) => {
	const { mode } = props;
	const history = useHistory();
	let location = useLocation();
	const { [`outletID`]: outletID, menuItemID } = useParams<
		Record<string, any>
	>();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const menuData: any = location?.state;

	const editData = menuData?.menuItem as any;
	const selectionData = menuData?.menuOptionItem as any;

	const [
		loadMenuItem,
		{
			called: menuItemCalled,
			loading: menuItemLoading,
			data: { getMenuItem } = { getMenuItem: [] },
		},
	] = useGetMenuItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuItem }) => {},
	});

	const {
		data: { getTaxSchemeListing } = { getTaxSchemeListing: [] },
		loading: taxSchemeListingLoading,
		refetch,
	} = useGetTaxSchemeListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			accountID: user?.accountID,
		},
	});

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
	} = useUploadAttachment();

	const [
		loadAttachment,
		{ loading: attachmentLoad, error: attachmentError, data: attachmentData },
	] = useAttachmentListingLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: data => {
			//  handleEditUpload(data?.attachmentListing);
			previewFiles.push(...data?.attachmentListing?.map(x => x?.fileURL));
		},
	});

	useEffect(() => {
		if (mode === 'edit') {
			loadAttachment({ variables: { refID: editData?.ID } });
		}
	}, [mode, editData]);

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()).trim(),
		nativeName: CommonYupValidation.requireField(
			SystemMsgs.nativeName(),
		).trim(),
		kitchenName: CommonYupValidation.requireField(
			SystemMsgs.kitchenName(),
		).trim(),
		description: CommonYupValidation.requireField(
			SystemMsgs.description(),
		).trim(),
		familyID: CommonYupValidation.requireField(SystemMsgs.family()),
		majorID: CommonYupValidation.requireField(SystemMsgs.major()),
		// taxSchemeID: CommonYupValidation.requireField('Tax Scheme is required'),
		// kitchenPrinterID: CommonYupValidation.requireField(
		// 	SystemMsgs.kitchenPrinter(),
		// ),
		defaultQuantity: yup
			.string()
			.when('isQuantityControlled', {
				is: true,
				then: yup.string().required('Default Quantity is required'),
			})
			.nullable(),
		uomID: yup
			.string()
			.when('isByWeight', {
				is: true,
				then: yup.string().required('Weight UOM is required'),
			})
			.nullable(),
			standardCost: yup.string().required('Standard Cost is required'),
	});

	const [isOpenItemChecked, setIsOpenItemChecked] = useState(
		getMenuItem[0]?.isOpenItem || editData?.isOpenItem || false,
	);
	const [isOpenPriceChecked, setIsOpenPriceChecked] = useState(
		getMenuItem[0]?.isOpenPrice || editData?.isOpenPrice || false,
	);

	const handleOpenItemChange = () => {
		setIsOpenItemChecked(!isOpenItemChecked);
		setIsOpenPriceChecked(false);
	};

	const handleOpenPriceChange = () => {
		setIsOpenPriceChecked(!isOpenPriceChecked);
		setIsOpenItemChecked(false);
	};

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		getValues,
		errors,
		clearErrors,
		formState,
	} = useForm<MenuItemForm>({
		defaultValues: {
			name: editData ? getMenuItem[0]?.name || editData?.name : '',
			nativeName: editData
				? getMenuItem[0]?.nativeName || editData?.nativeName
				: '',
			kitchenName: editData
				? getMenuItem[0]?.kitchenName || editData?.kitchenName
				: '',
			description: editData
				? getMenuItem[0]?.description || editData?.description
				: '',
			isTaxable: editData
				? getMenuItem[0]?.isTaxable || editData?.isTaxable
				: false,
			isServiceChargeable: editData
				? getMenuItem[0]?.isServiceChargeable || editData?.isServiceChargeable
				: false,
			isQuantityControlled: editData
				? getMenuItem[0]?.isQuantityControlled || editData?.isQuantityControlled
				: false,
			isChefRecommended: editData
				? getMenuItem[0]?.isChefRecommended || editData?.isChefRecommended
				: false,
			isOpenItem: isOpenItemChecked,
			isOpenPrice: isOpenPriceChecked,
			isByWeight: editData
				? getMenuItem[0]?.isByWeight || editData?.isByWeight
				: false,
			uomID: editData ? getMenuItem[0]?.uomID || editData?.uomID : '',
			defaultQuantity: editData
				? getMenuItem[0]?.defaultQuantity || editData?.defaultQuantity
				: '',
			isActive: editData ? getMenuItem[0]?.isActive || editData?.isActive : '',
			standardCost: editData
				? getMenuItem[0]?.standardCost || editData?.standardCost
				: 0,
		},
		resolver: yupResolver(yupSchema),
		mode: 'onSubmit',
	});

	useEffect(() => {
		if (mode === 'edit' && selectionData?.length > 0) {
			register('option');
			setValue('option', selectionData);
		}
	}, [mode, selectionData]);

	const [optionItemList, setOptionItemList] = useState([]);
	const [optionID, setOptionID] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [selection, setSelection] = useState(0);
	const [selectedKPIDs, setSelectedKPIDs] = useState([]);
	const [selectedKPs, setSelectedKPs] = React.useState<any[]>([]);
	const [allocateKP, setAllocateKP] = useState(null);
	const [defaultKP, setDefaultKP] = useState(null);
	const [isActive, setIsActive] = useState(
		// true,
		editData?.isActive != null ? editData?.isActive : true,
	);

	const handleIsActiveCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
		setIsActive(!isActive);
	};

	const refetchQueries = [
		{
			query: GetMenuItemDocument,
			variables: {
				isSetMenu: false,
				outletID: outletID,
				orderByAsc: 'name',
			},
		},
		{
			query: GetDailyMenuItemAvailabilityDocument,
			variables: {
				outletID: outletID,
			},
		},
	];

	const onSubmit = (data: any) => {
		console.log('data', data);
		let topInput = {
			...(mode === 'edit' ? { ID: menuItemID } : null),
			name: data?.name,
			nativeName: data?.nativeName,
			kitchenName: data?.kitchenName,
			description: data?.description,
			familyID: data?.familyID,
			majorID: data?.majorID,
			taxSchemeID: selectedOption ? selectedOption?.id : display?.id,
			kitchenPrinterID: selectedKPIDs[0] || null,
			kitchenPrinter2ID: selectedKPIDs[1] || null,
			outletID: outletID,
			isOpenItem: isOpenItemChecked,
			isOpenPrice: isOpenPriceChecked,
			isChefRecommended: data?.isChefRecommended,
			isQuantityControlled: data?.isQuantityControlled,
			defaultQuantity: data?.isQuantityControlled
				? parseInt(data?.defaultQuantity)
				: null,
			isDiscountable: data?.isDiscountable,
			isSetMenu: false, // dont know about this one
			isByWeight: data?.isByWeight,
			uomID: data?.isByWeight ? data?.uomID : null,
			file: files,
			isActive: isActive,
			standardCost: parseFloat(data?.standardCost)
		};

		let optionInput;
		if (data?.option?.length > 0) {
			optionInput = data.option.map(v => {
				return {
					outletID: outletID,
					optionID: v.optionID,
					maxSelections:
						typeof v.maxSelections === 'string'
							? parseFloat(v.maxSelections)
							: v.maxSelections,
					menuItemOptionItems: v.menuItemOptionItem.map(x => {
						return {
							outletID: outletID,
							price: x.price,
							optionItemID: x.optionItemID,
						};
					}),
				};
			});
		}

		if (mode == 'add') {
			createMenuItem({
				variables: {
					menuItemInput: topInput,
					menuItemOptionInput: optionInput && [...optionInput],
				},
				refetchQueries: refetchQueries,
			});
		} else {
			updateMenuItem({
				variables: {
					menuItemInput: topInput,
					menuItemOptionInput: optionInput && [...optionInput],
				},
				refetchQueries: refetchQueries,
			});
		}
	};

	//////////////// need to check what is this for /////////////////
	useEffect(() => {
		if (mode == 'edit') {
			loadMenuItem({
				variables: {
					ID: menuItemID,
					outletID,
				},
			});
		}
	}, [mode]);

	useEffect(() => {
		loadFamily({
			variables: {
				majorID: watch('majorID') as string,
			},
		});
	}, [watch('majorID')]);

	const { data: getMajorData, loading: majorDataLoading } = useGetMajorQuery({
		fetchPolicy: 'network-only',
		variables: { outletID },
	});

	const {
		data: { getKitchenPrinter } = { getKitchenPrinter: [] },
		loading: kitchenPrinterLoading,
	} = useGetKitchenPrinterQuery({
		fetchPolicy: 'network-only',
		variables: { outletID },
	});

	const [
		loadFamily,
		{ data: getFamily, loading: familyLoading },
	] = useGetFamilyLazyQuery({ fetchPolicy: 'network-only' });
	const { data: getUOM } = useGetUomQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// },
	});
	useEffect(() => {
		if (mode === 'edit' && !menuItemLoading) {
			const tempSelectedKPIDs = getMenuItem?.map(menuItem => {
				return {
					kitchenPrinter1: menuItem?.kitchenPrinterID,
					kitchenPrinter2: menuItem?.kitchenPrinter2ID,
				};
			});
			let arraysOfIDs = tempSelectedKPIDs.map(v => Object.values(v)).flat();
			setSelectedKPIDs(arraysOfIDs.filter(x => x !== null));
		}
	}, [mode, menuItemLoading]);
	const [
		createMenuItem,
		{ data: createdMealPeriodData, loading: createMealPeriodLoading },
	] = useCreateMenuItemWithOptionsMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			// history.push(`/subcontract/${consubconID}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-item`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 1000);
		},
	});

	const [
		updateMenuItem,
		{ data: updatedMealPeriodData, loading: updateMealPeriodLoading },
	] = useUpdateMenuItemWithOptionsMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconID}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-item`,
					state: { success: true, msgMode: 'update' },
				});
				//history.goBack();
			}, 1000);
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const checkbox = (checkedCondition: boolean, name: string, label: string) => {
		return (
			<Controller
				as={
					<FormControlLabel
						style={{ fontWeight: 'bold' }}
						control={
							<Checkbox
								defaultChecked={checkedCondition}
								name={name}
								color="primary"
								ref={register}
								onChange={e => {
									setValue(name, e.target.checked);
								}}
							/>
						}
						label={label}
					/>
				}
				name={name}
				ref={register}
				control={control}
			/>
		);
	};

	const handleCheckboxChange = (event: any) => {
		let checkedKPIDs = event?.target?.value;
		// let defaultCheckedKPIDs = event?.props.defaultValue;

		setSelectedKPIDs(checkedKPIDs);

		setAllocateKP(
			checkedKPIDs?.map(kpID => {
				const checkedKP = getKitchenPrinter?.filter(KP => {
					return KP?.ID === kpID;
				})[0];
				return {
					kitchenPrinter2ID: checkedKP?.ID,
					kitchenPrinterRefTable: 'p_kitchenPrinter',
				};
			}),
		);
		// setDefaultKP(defaultCheckedKPIDs);
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const checkboxControl = (checkedCondition, name, label, onChange) => {
		return (
			<Controller
				as={
					<FormControlLabel
						style={{ fontWeight: 'bold' }}
						control={
							<Checkbox
								checked={checkedCondition}
								name={name}
								color="primary"
								onChange={onChange}
							/>
						}
						label={label}
					/>
				}
				name={name}
				control={control}
			/>
		);
	};

	const [selectedOption, setSelectedOption] = useState(null);
	const [isTouch, setIsTouch] = useState(false);
	const [display, setDisplay] = useState(null);

	useEffect(() => {
		setDisplay(
			getTaxSchemeListing.find(
				x =>
					x?.id ===
					((editData && editData.taxSchemeID) || outletArray[0]?.taxSchemeID),
			),
		);
	}, [editData, getTaxSchemeListing, outletArray]);

	return (
		<>
			{menuItemLoading && <Loading />}
			{attachmentLoad && <Loading />}
			{majorDataLoading && <Loading />}
			{kitchenPrinterLoading && <Loading />}
			{familyLoading && <Loading />}
			{outletLoading && <Loading />}
			{createMealPeriodLoading && <Loading />}
			{updateMealPeriodLoading && <Loading />}
			{taxSchemeListingLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Menu Items', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<ContentWrapper footer>
				<CardContents devFullWidth={true}>
					<Grid container style={{ height: '0px' }}>
						<Grid item xs={9} className="left"></Grid>
						{mode === 'edit' && (
							<Grid item xs={3} className="right">
								<FormControlLabel
									control={
										<Checkbox
											checked={isActive}
											onChange={handleIsActiveCheckbox}
											name="active"
											color="primary"
										/>
									}
									label="Active"
									//labelPlacement="end"
								/>
							</Grid>
						)}
					</Grid>
					<Controller
						as={TextField}
						name="name"
						label="Name (Standard)"
						fullWidth
						autoComplete="off"
						multiline={true}
						margin="normal"
						defaultValue={editData?.name || ''}
						ref={register}
						control={control}
						required
						helperText={errors?.name?.message}
						error={errors?.name ? true : false}
					/>

					<Controller
						as={TextField}
						name="nativeName"
						label="Name (Native)"
						fullWidth
						autoComplete="off"
						multiline={true}
						margin="normal"
						defaultValue={editData?.nativeName || ''}
						ref={register}
						control={control}
						helperText={errors?.nativeName?.message}
						error={errors?.nativeName ? true : false}
						required
					/>

					<Controller
						as={TextField}
						name="kitchenName"
						label="Name (Kitchen)"
						fullWidth
						autoComplete="off"
						multiline={true}
						margin="normal"
						defaultValue={editData?.kitchenName || ''}
						ref={register}
						control={control}
						helperText={errors?.kitchenName?.message}
						error={errors?.kitchenName ? true : false}
						required
					/>

					<Controller
						as={TextField}
						name="description"
						label="Description"
						fullWidth
						autoComplete="off"
						multiline={true}
						margin="normal"
						defaultValue={editData?.description || ''}
						ref={register}
						control={control}
						required
						helperText={errors?.description?.message}
						error={errors?.description ? true : false}
					/>

					<Controller
						as={
							<TextField
								name="majorID"
								defaultValue={editData?.majorID || getMenuItem[0]?.majorID}
							>
								{getMajorData?.getMajor?.map(x => (
									<MenuItem key={x.ID} value={x.ID}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						}
						name="majorID"
						margin="normal"
						label="Major Group"
						fullWidth
						autoComplete="off"
						multiline={true}
						select
						control={control}
						ref={register}
						required
						helperText={errors?.majorID?.message}
						error={errors?.majorID ? true : false}
						// inputRef={register()}
						defaultValue={getMenuItem[0]?.majorID || editData?.majorID || null}
					/>

					{
						<Controller
							as={
								<TextField
									name="familyID"
									defaultValue={editData?.familyID || getMenuItem[0]?.familyID}
								>
									{getFamily?.getFamily?.map(x => (
										<MenuItem key={x.ID} value={x.ID}>
											{x.name}
										</MenuItem>
									))}
								</TextField>
							}
							name="familyID"
							margin="normal"
							disabled={!watch('majorID')}
							label="Family Group"
							fullWidth
							autoComplete="off"
							multiline={true}
							select
							control={control}
							ref={register}
							required
							helperText={errors?.familyID?.message}
							error={errors?.familyID ? true : false}
							// inputRef={register()}
							defaultValue={
								getMenuItem[0]?.familyID || editData?.familyID || null
							}
						/>
					}

					{/* <Controller
						as={
							<TextField
								name="kitchenPrinterID"
								defaultValue={
									editData?.kitchenPrinterID || getMenuItem[0]?.kitchenPrinterID
								}
							>
								{getKitchenPrinter?.map((x) => (
									<MenuItem key={x.ID} value={x.ID}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						}
						name="kitchenPrinterID"
						margin="normal"
						label="Kitchen Printer"
						fullWidth
						autoComplete="off"
						multiline={true}
						select
						control={control}
						ref={register}
						// required
						helperText={errors?.kitchenPrinterID?.message}
						error={errors?.kitchenPrinterID ? true : false}
						// inputRef={register()}
						defaultValue={
							getMenuItem[0]?.kitchenPrinterID ||
							editData?.kitchenPrinterID ||
							null
						}
					/> */}

					<Controller
						as={
							<TextField
								SelectProps={{
									input: <Input />,
									renderValue: (selected: any) =>
										selected
											?.map(
												el =>
													getKitchenPrinter?.filter(KP => KP?.ID === el)[0]
														?.name,
											)
											.join(', '),

									onChange: event => {
										handleCheckboxChange(event);
										setValue(`kitchenPrinter2ID`, event?.target?.value);
										clearErrors('name');
									},
									multiple: true,
									value:
										selectedKPIDs ||
										(getMenuItem[0]?.kitchenPrinterID,
										getMenuItem[0]?.kitchenPrinter2ID) ||
										[],
								}}
								// helperText={errors?.doNo?.message}
								// error={errors?.doNo ? true : false}
								select
							>
								{getKitchenPrinter.map((KP, index) => (
									<MenuItem key={index} value={KP?.ID}>
										<Checkbox
											color="primary"
											checked={
												selectedKPIDs?.indexOf(KP?.ID) > -1
												// ||
												// (getMenuItem[0]?.kitchenPrinter2ID?.indexOf(KP?.ID),
												// getMenuItem[0]?.kitchenPrinterID.indexOf(KP?.ID) > -1
											}
										/>
										<ListItemText
											primary={
												<Grid container justify="flex-start">
													<Grid
														item
														xs={8}
														component="span"
														className="xsTitle "
													>
														{KP?.name}
													</Grid>
												</Grid>
											}
										/>
									</MenuItem>
								))}
							</TextField>
						}
						name="kitchenPrinter2ID"
						label="Kitchen Printer"
						margin="normal"
						autoComplete="off"
						fullWidth
						multiline={true}
						select
						control={control}
						ref={register}
					/>
					<Autocomplete
						options={getTaxSchemeListing}
						fullWidth
						getOptionLabel={option => option?.name}
						value={isTouch === false ? display : selectedOption}
						onChange={(event, value) => {
							setIsTouch(true);
							setSelectedOption(value);
						}}
						renderInput={params => (
							<TextField
								{...params}
								label="Tax Scheme"
								variant="standard"
								name="taxSchemeID"
								helperText={
									!selectedOption?.id && isTouch === true
										? 'Tax Scheme is required'
										: null
								}
								error={!selectedOption?.id && isTouch === true ? true : false}
							/>
						)}
						renderOption={option => (
							<div>
								<div className="fw-normal" style={{ fontSize: '13px' }}>
									<b>{option.name}</b>
								</div>
								<div className="fw-normal" style={{ fontSize: '13px' }}>
									{option.description ? option.description : '-'}{' '}
									{option?.isExclusive === true ? '(E)' : '(I)'}
								</div>
							</div>
						)}
					/>
						<Controller
							as={
								<NumberFormat
									customInput={TextField}
									allowNegative={false}
									fullWidth
									autoComplete="off"
									isNumericString
									decimalScale={2}
									fixedDecimalScale
									onValueChange={(value: any) => {
										const { floatValue } = value;
									}}
								/>
							}
							name="standardCost"
							label="Standard Cost"
							control={control}
							ref={register}
							defaultValue={getMenuItem[0]?.standardCost ||
								editData?.standardCost ||
								''}
							helperText={errors?.standardCost?.message}
							error={errors?.standardCost ? true : false}
							required
						
						/>

					<FileUploadInput
						label="Upload Attachment"
						name="Upload Attachment"
						files={files}
						onHandleUploadChange={handleUploadChange}
						multiple
						style={{ marginTop: '16px' }}
						imagePreview={
							<>
								{previewFiles?.map((v, i) => (
									<UploadPreview
										remove
										key={v}
										src={v}
										onClick={() => removeFile(i)}
										mediaType={
											files[i]?.type ??
											attachmentData?.attachmentListing[i]?.mediaType
										}
									/>
								))}
							</>
						}
					/>

					<Grid container>
						<Grid item xs={7}>
							{checkboxControl(
								isOpenItemChecked,
								'isOpenItem',
								'Open Item',
								handleOpenItemChange,
							)}
						</Grid>
						<Grid item xs={5}>
							{checkboxControl(
								isOpenPriceChecked,
								'isOpenPrice',
								'Open Price',
								handleOpenPriceChange,
							)}
						</Grid>

						<Grid item xs={7}>
							{checkbox(
								getMenuItem[0]?.isChefRecommended! ||
									editData?.isChefRecommended ||
									false,
								'isChefRecommended',
								'Chef Recommended',
							)}
						</Grid>
						<Grid item xs={5}>
							{checkbox(
								getMenuItem[0]?.isDiscountable! ||
									editData?.isDiscountable ||
									false,
								'isDiscountable',
								'Discountable',
							)}
						</Grid>

						<Grid item xs={7}>
							<Controller
								as={
									<FormControlLabel
										style={{ fontWeight: 'bold' }}
										control={
											<Checkbox
												defaultChecked={
													getMenuItem[0]?.isByWeight! ||
													editData?.isByWeight ||
													false
												}
												name="isByWeight"
												color="primary"
												// className="left"
												onChange={e => {
													setValue('isByWeight', e.target.checked);
												}}
												ref={register}
											/>
										}
										label="By Weight"
									/>
								}
								name="isByWeight"
								ref={register}
								control={control}
							/>
							{/* {checkbox(
								getMenuItem[0]?.isByWeight! || editData?.isByWeight || false,
								'isByWeight',
								'By Weight',
							)} */}
						</Grid>

						<Grid item xs={5}>
							<Controller
								as={
									<TextField
										defaultValue={editData?.uomID || getMenuItem[0]?.uomID}
									>
										{getUOM?.getUOM?.map(x => (
											<MenuItem key={x.ID} value={x.ID}>
												{x.name}
											</MenuItem>
										))}
									</TextField>
								}
								name="uomID"
								label="Weight UOM"
								fullWidth
								autoComplete="off"
								multiline={true}
								style={{ margin: '0px' }}
								select
								disabled={
									!watch(`isByWeight`) && !getMenuItem[0]?.isByWeight === true
										? true
										: !watch(`isByWeight`)
										? true
										: false
								}
								helperText={errors?.uomID?.message}
								error={errors?.uomID ? true : false}
								defaultValue={getMenuItem[0]?.uomID || editData?.uomID}
								// ref={register}
								ref={register()}
								control={control}
							/>
						</Grid>

						<Grid item xs={7}>
							<Controller
								as={
									<FormControlLabel
										style={{ fontWeight: 'bold' }}
										control={
											<Checkbox
												defaultChecked={
													getMenuItem[0]?.isQuantityControlled! ||
													editData?.isQuantityControlled ||
													false
												}
												name="isQuantityControlled"
												color="primary"
												// className="left"
												onChange={e => {
													setValue('isQuantityControlled', e.target.checked);
												}}
												ref={register}
											/>
										}
										label="Qty Count"
									/>
								}
								name="isQuantityControlled"
								ref={register}
								control={control}
							/>
							{/* {checkbox(
								getMenuItem[0]?.isQuantityControlled! ||
									editData?.isQuantityControlled ||
									false,
								'isQuantityControlled',
								'Qty Count',
							)} */}
						</Grid>
						<Grid item xs={5} style={{ paddingBottom: '8px' }}>
							<Controller
								as={
									<NumberFormat
										name="defaultQuantity"
										label="Default Qty"
										customInput={TextField}
										autoComplete="off"
										allowNegative={false}
										multiline={true}
										style={{ margin: '0px' }}
										disabled={
											!watch(`isQuantityControlled`) &&
											!getMenuItem[0]?.isQuantityControlled === true
												? true
												: !watch(`isQuantityControlled`)
												? true
												: false
										}
										defaultValue={
											getMenuItem[0]?.defaultQuantity ||
											editData?.defaultQuantity ||
											''
										}
										helperText={errors?.defaultQuantity?.message}
										error={errors?.defaultQuantity ? true : false}
										inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
									/>
								}
								name="defaultQuantity"
								ref={register}
								control={control}
							/>
						</Grid>
					</Grid>
				</CardContents>

				<MenuItemOptionsForm
					outletID={outletID}
					setOptionID={setOptionID}
					setOptionItemList={setOptionItemList}
					setOpenDialog={setOpenDialog}
					setOpenEditDialog={setOpenEditDialog}
					openEditDialog={openEditDialog}
					openDialog={openDialog}
					optionItemList={optionItemList}
					optionID={optionID}
					selection={selection}
					setSelection={setSelection}
					menuWatch={watch}
					menuRegister={register}
					menuSetValue={setValue}
					menuGetValues={getValues}
				/>
			</ContentWrapper>

			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						color:
							formState.isSubmitting ||
							(!selectedOption?.id && isTouch === true)
								? 'initial '
								: 'primary',
						disabled:
							formState.isSubmitting ||
							(!selectedOption?.id && isTouch === true),
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/menu-item`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-item`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
