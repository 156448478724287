import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import {
	CommonStatus,
	OrderItemStatus,
	OrderStatus,
	useGetOrderLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetPrintDataSubscription,
	useGetPrinterOrderItemLazyQuery,
	useGetPrinterOrderItemQuery,
	useGetTableQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { WSclient } from 'POSClient';
import {
	printReceipt,
	printReceiptText,
} from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';

const useStyles = makeStyles({
	spaced: {
		['& > *']: {
			marginRight: '0.5rem',
		},
		['& :not(span)']: {
			width: '0.8rem',
			height: '0.8rem',
		},
	},
});

export const PrinterServerHome = (props: any) => {
	const { outletID } = useParams<Record<string, any>>();
	const classes = useStyles();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletSearch = JSON.parse(localStorage.getItem('searchFilter'));
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const history = useHistory();

	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const { data: { getOutlet } = { getOutlet: [] } } = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	//subscription //subscription //subscription

	const {
		loading: getPrintDataLoading,
		data: { getPrintData } = { getPrintData: {} as any },
	} = useGetPrintDataSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	const {
		data: { getPrinterOrderItem } = { getPrinterOrderItem: [] },
		loading: getPrinterOrderItemLoading,
	} = useGetPrinterOrderItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: getPrintData.orderID,
			orderItemIDs: getPrintData?.orderItemIDs,
		},
		onCompleted: () => {
			generateReceiptData();
		},
	});

	const {
		data: { getOrder } = { getOrder: [] },
		loading: orderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: getPrintData?.orderID,
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [uniqueKpArray, setUniqueKpArray] = useState([]);
	const [kpObj, setKpObject] = useState({});
	const [printerName, setPrinterName] = useState(null);
	const [kitchenPrinterObj, setKitchenPrinterObj] = useState([]);

	// useEffect(() => {
	// 	console.log('1');
	// 	if (getPrintData?.outletName !== undefined) {
	// 		console.log('2');
	// 		loadOrder({
	// 			variables: { ID: getPrintData?.orderID },
	// 		});
	// 		loadPrinterOrderItem({
	// 			variables: {
	// 				orderID: getPrintData.orderID,
	// 				orderItemIDs: getPrintData?.orderItemIDs,
	// 			},
	// 		});
	// 	}
	// }, [getPrintData]);

	useEffect(() => {
		if (getPrinterOrderItem?.length > 0) {
			generateReceiptData();
		}
	}, [getPrinterOrderItem]);

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getPrinterOrderItem
				?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
				?.map(oi => oi?.menuItem?.kitchenPrinter?.name),
		);
		setUniqueKpArray([...uniqueprinterName!]);

		let temporaryObject = {};

		getPrinterOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		setKpObject(temporaryObject);
	};

	console.log('getPrintData', getPrintData);
	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	let obj;
	const handlePrinting = async () => {
		if (uniqueKpArray?.length > 0) {
			await uniqueKpArray?.map(async (printerName, index) => {
				obj = kpObj[printerName];
				setKitchenPrinterObj(obj);
				setPrinterName(printerName);

				printReceiptText(
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					printerName,
					'server',
					null,
					'order-receipt',
					null,
					getPrintData.outletName,
					getOrder[0],
					getOrder[0]?.table,
					obj,
					getPrintData.orderMode,
					null,
					getPrintData.voidQuantity,
					getPrintData.reasonCode,
					getPrintData.remark,

					null,
					null,
					null,
					null,
					null,
					getUsersByAccountAndSoftware,
					null,
					null,
					null,
				);
			});
		}
		return true;
	};

	useEffect(() => {
		if (uniqueKpArray?.length > 0 && getOrder?.length > 0) {
			// printReceipt(getPrintData.dataURL, getPrintData.printerName, 'server');
			handlePrinting();
		}
	}, [uniqueKpArray, getOrder]);

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Print Server"
				title={getOutlet[0]?.name}
				routeSegments={[{ name: 'Server', current: true }]}
			/>

			<ContentWrapper float>
				<EmptyList title="Listening to print requests..." />
			</ContentWrapper>
		</>
	);
};
