import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import { OutletIcon } from 'assets';
import DeliveryColorIcon from 'assets/icons/delivery_color.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import PickupColorIcon from 'assets/icons/pickup_color.svg';
import TimeBlackIcon from 'assets/icons/time_black.svg';
import AppContext from 'containers/App/Store/AppContext';
import {
	OrderStatus,
	PosPermission,
	useGetAssignedOutletByUserQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetOutletQuery,
	useGetStaffLazyQuery,
	useGetTakeawayOrderQuery,
} from 'generated/graphql';
import { formatDateTime, formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const TakeawayList = (props: any) => {
	useEffect(() => {
		console.log('TakeawayList');
	}, []);
	const history = useHistory();
	const { outletID }: any = useParams();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const [openSwitch, setOpenSwitch] = useState(null);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const { handlePermDisabled } = usePermissionChecker();

	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: () => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getOutlet[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});

			const filteredOutlets: any = getOutlet[0] ?? {};

			localStorage.setItem('Outlet', JSON.stringify(filteredOutlets));
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			hotelID: getOutlet[0]?.outletChannel?.hotelID,
			outletID: outletID,
		},
	});

	const {
		loading: takeawayOrderLoading,
		data: { getTakeawayOrder } = { getTakeawayOrder: [] },
	} = useGetTakeawayOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (getTakeawayOrder && getTakeawayOrder.length > 0) {
			setOriginalListing(getTakeawayOrder);
		} else if (getTakeawayOrder?.length === 0) {
			setOriginalListing(null);
		}
	}, [getTakeawayOrder]);

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser.length > 0) {
			setOriginalListing1(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	const amount = order => {
		const orderItem = order?.orderItem?.filter(x => x?.amount >= 0);

		const totalAmount = orderItem?.map(el => {
			return el?.totalInfo?.totalAmount;
		});
		const ttlAmt = totalAmount.reduce((a: any, b: any) => {
			return a + b;
		}, 0);

		const serviceCharge = orderItem?.map(el => {
			return el?.totalInfo?.totalServiceCharge;
		});
		const srvChg = serviceCharge.reduce((a: any, b: any) => {
			return a + b;
		}, 0);

		const tax = orderItem?.map(el => {
			return el?.totalInfo?.totalTax;
		});
		const tx = tax.reduce((a: any, b: any) => {
			return a + b;
		}, 0);

		const total = ttlAmt + srvChg + tx;

		return total;
	};

	const patronInfo = order => {
		if (order?.patron?.patronClass === 'Visitor') {
			return order?.patronName;
		} else if (order?.patron?.patronClass === 'Hotel Guest') {
			const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === order?.patronAccountID,
			)[0];
			return `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
				selectedGuest?.GuestName
			}`;
		} else if (order?.patron?.patronClass === 'Staff') {
			return getStaff?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (order?.patron?.patronClass === 'Officer') {
			return getOfficer?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (order?.patron?.patronClass === 'Member') {
			return 'Member';
		}
	};

	return (
		<>
			{outletLoading && <Loading />}
			{takeawayOrderLoading && <Loading />}
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				// onClick={() =>
				// 	history.push({
				// 		pathname: `/menu/outlet-app/${outletID}/table/grid`,
				// 	})
				// }
				// mainBtn="back"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Takeaway', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${new Date().toLocaleDateString('en-GB')} ${
									currentMealPeriod?.name
							  } ${formatTime(currentMealPeriod?.startTime)} - ${formatTime(
									currentMealPeriod?.endTime,
							  )}`
							: `No applicable meal period at the moment`,
						current: true,
					},
				]}
			/>
			<SearchHeader
				title="Takeaway Listing"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['docNo', 'patronName']);
				}}
				fixed
				search
				onCloseAction={() => handleSearch('', [])}
			/>
			<ContentWrapper search>
				<List className="core-list">
					{!takeawayOrderLoading &&
						(getTakeawayOrder?.length === 0 ||
						getTakeawayOrder === undefined ||
						getTakeawayOrder === null ? (
							<EmptyList
								title="No takeaway order found for today"
								subtitle="Add a new takeaway order to proceed"
							/>
						) : (
							filteredList?.map((el, index) => (
								<ListItem
									disabled={
										el?.status === OrderStatus.PickedUp
										// || el?.status === OrderStatus.Closed
									}
								>
									<ListItemText
										primary={
											<>
												<div className="flex-space">
													<span
														className="xsTitle"
														style={{
															textDecoration:
																el?.status === 'CANCELLED'
																	? 'line-through'
																	: null,
														}}
													>
														{`#${el?.docNo}`} | {patronInfo(el)}
													</span>

													<img
														src={
															el?.serviceMode === 'PICKUP'
																? PickupColorIcon
																: DeliveryColorIcon
														}
														style={{
															width: '13px',
															height: '13px',
															paddingLeft: '3px',
														}}
													/>
												</div>

												{/* <img
													src={
														el?.status === 'OPEN'
															? DollarRedIcon
															: OrangeDollarIcon
													}
													style={{
														width: '13px',
														height: '13px',
														paddingRight: '3px',
													}}
												/> */}
												<span
													className={
														el?.outstandingAmount > 0
															? 'xsTitle color-red'
															: 'xsTitle color-orange'
													}
													style={{
														textDecoration:
															el?.status === 'CANCELLED'
																? 'line-through'
																: null,
													}}
												>
													{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
														el?.outstandingAmount,
													)}`}
												</span>
											</>
										}
										secondary={
											<>
												<span className="flex-space desc">
													{el?.contactNo && (
														<>
															<img
																src={PhoneIcon}
																style={{
																	width: '10px',
																	height: '10px',
																	paddingRight: '3px',
																}}
															/>
															<span className="primary-click-text">
																{el?.contactNo}
															</span>

															<TextSeparator />
														</>
													)}

													<img
														src={TimeBlackIcon}
														style={{
															width: '10px',
															height: '10px',
															paddingRight: '3px',
														}}
													/>

													<span className="desc">
														{el?.pickupTime
															? formatTime(el?.pickupTime)
															: formatDateTime(el?.createdTs)}
													</span>
												</span>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											key={0}
											// disabled={
											// 	el?.status === 'PICKED_UP' || el?.status === 'CANCELLED'
											// }
											onClick={e => {
												history.push({
													pathname: `/menu/outlet-app/${outletID}/order/${el?.ID}/takeaway/detail`,
													state: getTakeawayOrder?.filter(
														x => x?.ID === el?.ID,
													),
												});
											}}
										>
											<KeyboardArrowRight />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
						))}
				</List>
			</ContentWrapper>

			<div style={{ marginBottom: '100px' }}></div>

			<FloatButton
				onClick={() =>
					history.push(`/menu/outlet-app/${outletID}/order/takeaway/add`)
				}
				disabled={handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.TakeawayCreate,
				})}
			/>

			<CommonDialogV2
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div
											className="title"
											style={{ color: '#ff9800' }}
										>{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												// className="m-b-20"
												id="standard-basic"
												label="Search here..."
												fullWidth
												// value={`${filteredList?.name ?? 0}`}
												onChange={e => {
													handleSearch1(`'"${e?.target?.value}"`, ['name']);
												}}
												// fixed
												// search
												// onClose={() => handleSearch('', [])}
											/>
										</ListItem>
									</List>
								</div>
							) : null}
							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList1?.map((outlet, index) => (
										<>
											<ListItem
												style={{
													backgroundColor:
														outlet?.ID === outletID ? '#E5EDFF' : null,
												}}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/takeaway`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
														// loadCurrentMealPeriod({
														// 	variables: { outletID: outlet?.ID },
														// });
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
