import { CreatePassword } from 'containers/AccountModule/CreatePassword';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ActivatedUser } from '../../../containers/AccountModule/ActivatedUser';
import { ForgotPassword } from '../../../containers/AccountModule/ForgotPassword';
import { Login } from '../../../containers/AccountModule/Login';
import { ResetPassword } from '../../../containers/AccountModule/ResetPassword';
import AppContext from '../Store/AppContext';
import { PrivateRoute } from './PrivateRoute';
import { Bill3rdPartyEInvoiceForm } from 'containers/Bill3rdPartyEInvoiceModule/Bill3rdPartyEInvoiceForm';
import { EInvoiceQR } from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/EInvoiceQR';

const Routes = () => {
	const { globalState } = useContext(AppContext as any);
	return (
		<Switch>
			<Route path="/user/activate/:token" exact>
				<ActivatedUser />
			</Route>
			<Route path="/user/create-password/:token" exact>
				<CreatePassword />
			</Route>
			<Route path="/reset-password/:token" exact>
				<ResetPassword />
			</Route>
			<Route path="/forgot-password" exact>
				<ForgotPassword />
			</Route>
			<Route path="/login" exact>
				<Login />
			</Route>
			<Route path="/qrinvoice/3rd-party/:outletID/:billID" exact>
				<Bill3rdPartyEInvoiceForm />
			</Route>
			<Route path="/qr-invoice/:outletID/:billID" exact>
				<EInvoiceQR />
			</Route>

			{globalState.routes.map((el, index) => {
				return (
					<PrivateRoute key={index} {...el.props}>
						{/* <RouteSuspense> */}
						{el.component}
						{/* </RouteSuspense> */}
					</PrivateRoute>
				);
			})}
		</Switch>
	);
};

export default Routes;
