import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
	Backdrop,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InstallBanner } from 'components/InstallBanner/InstallBanner';
import { Layout } from 'components/Layout/Layout';
import { useGetOutletQuery, useLogoutMutation } from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/hooks/useBroadcastChannel';
import { useInstallPrompt } from 'helpers/hooks/useInstallPrompt';
import { useServiceWorker } from 'helpers/hooks/useServiceWorker';
import { createBrowserHistory } from 'history';
import localForage from 'localforage';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router';
import { setAccessToken } from '../../AccessToken';
import '../../assets/styles/app.scss';
import { POSClient, posNodeRefreshUrl } from '../../POSClient';
import '../AccountModule/layout.scss';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarContext from './Store/SnackBarContext';
import { ADEWrapper } from './ADEWrapper';
import { SocketContextProvider } from 'containers/Socket/SocketContext';
export const history = createBrowserHistory();

interface AppProps {
	children?: any;
}
export const App = (props: AppProps) => {
	const { children } = props;
	const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
	const [loading, setLoading] = useState(true);
	const { userChannel } = useBroadcastChannel();
	const [logout, { client }] = useLogoutMutation({ client: POSClient as any });
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const key = sessionStorage?.getItem('key');
	const { isUpdateAvailable, updateAssets } = useServiceWorker();
	const routing: any = history?.location?.pathname;

	const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
	const [snackBarMsg, setSnackBarMsg] = useState<string>('');

	async function userLogout(userID) {
		await logout({ variables: { ID: userID } });
		setAccessToken('');
		await client!.resetStore();
	}

	const pathBeforeLogin = [
		'/authentication/404',
		'/reset-password',
		'/user/activate',
		'/user/create-password',
		'/login',
		'/forgot-password',
		'/qrinvoice/3rd-party',
		'/qr-invoice',
	];

	// const events = [
	// 	'load',
	// 	'mousemove',
	// 	'mousedown',
	// 	'click',
	// 	'scroll',
	// 	'keypress',
	// ];

	const isNotMainContent = () => {
		return pathBeforeLogin?.filter(v => routing?.includes(v))?.length > 0;
	};

	//  const App = ({ children }) => {
	// let timer;

	// const resetTimer = () => {
	// 	if (timer) clearTimeout(timer);
	// };

	// const handleLogoutTimer = () => {
	// 	if (history.location.pathname !== '/login') {
	// 		timer = setTimeout(() => {
	// 			// clears any pending timer.
	// 			resetTimer();
	// 			// Listener clean up. Removes the existing event listener from the window
	// 			Object.values(events).forEach(item => {
	// 				window.removeEventListener(item, resetTimer);
	// 			});
	// 			// logs out user
	// 			logoutAction();
	// 		}, 900000); // 10000ms = 10secs. You can change the time.
	// 	}
	// };

	// const logoutAction = () => {
	// 	localStorage?.removeItem('loggedInUser');
	// 	localForage?.removeItem('permission');
	// 	sessionStorage?.removeItem('key');
	// 	history.push(`/login`);
	// };

	useEffect(() => {
		fetch(posNodeRefreshUrl, {
			method: 'POST',
			credentials: 'include',
		}).then(async x => {
			const { accessToken } = await x.json();
			setAccessToken(accessToken);
			setLoading(false);
		});

		// Object.values(events).forEach(item => {
		// 	window.addEventListener(item, () => {
		// 		resetTimer();
		// 		handleLogoutTimer();
		// 	});
		// });

		userChannel.onmessage = (data: any) => {
			if (data?.payload?.type === 'SIGN_OUT' && data?.userId === user?.ID) {
				userLogout(data?.userId);
				localStorage?.removeItem('loggedInUser');
				localForage?.removeItem('permission');
				sessionStorage?.removeItem('key');
				history.push(`/login`);
			}
		};
	}, []);

	// const allRange = [];
	// for (var i = 0; i <= 1440; i++) {
	// 	allRange.push(i);
	// }

	// const getTimeRange = range => {
	// 	if (range.startTime < range.endTime) {
	// 		let timeRange = [];
	// 		for (var i = range.startTime; i <= range.endTime; i++) {
	// 			timeRange.push(i);
	// 		}
	// 		return timeRange;
	// 	} else {
	// 		let timeRange = [];
	// 		for (var i = range.endTime + 1; i <= range.startTime; i++) {
	// 			timeRange.push(i);
	// 		}
	// 		return allRange.filter(val => !timeRange.includes(val));
	// 	}
	// };

	// const MINUTE_MS = 60000;
	// setTimeout(() => {
	// 	setInterval(() => {
	// 		const currTs = new Date();
	// 		const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();
	// 		const allMealPeriodObj = localStorage.getItem('allMealPeriod');
	// 		if (allMealPeriodObj) {
	// 			const allMealPeriod = JSON.parse(allMealPeriodObj);
	// 			let currentMealPeriod = {};
	// 			var outletIDs = Object.keys(allMealPeriod);
	// 			outletIDs?.map(outletID => {
	// 				let currMealPeriod = allMealPeriod[outletID]?.filter(mealPeriod => {
	// 					const formattedExMp = {
	// 						startTime:
	// 							Number(mealPeriod?.startTime.split(':')[0]) * 60 +
	// 							Number(mealPeriod?.startTime.split(':')[1]),
	// 						endTime:
	// 							Number(mealPeriod?.endTime.split(':')[0]) * 60 +
	// 							Number(mealPeriod?.endTime.split(':')[1]),
	// 					};
	// 					if (getTimeRange(formattedExMp).includes(currTimeInt)) {
	// 						return mealPeriod;
	// 					}
	// 				});
	// 				currentMealPeriod[outletID] = currMealPeriod;
	// 			});
	// 			localStorage.setItem(
	// 				'currentMealPeriod',
	// 				JSON.stringify(currentMealPeriod),
	// 			);
	// 		}
	// 	}, MINUTE_MS);
	// }, MINUTE_MS - new Date().getSeconds() * 1000);

	// useEffect(() => {
	// 	if (!!!key && !!!user && isNotMainContent()) {
	// 		history.push('/login');
	// 	}
	// }, [key, user]);

	useEffect(() => {
		if (!!!key && !isNotMainContent()) {
			userLogout(user?.ID);
			localStorage?.removeItem('loggedInUser');
			localForage?.removeItem('permission');
			sessionStorage?.removeItem('key');
			history.push('/login');
		}
		if (
			(user == null || Object?.keys(user)?.length == 0) &&
			!isNotMainContent()
		) {
			localStorage?.removeItem('loggedInUser');
			localForage?.removeItem('permission');
			sessionStorage?.removeItem('key');
			history.push('/login');
		}
	}, [key, user]);

	const { promptable, promptToInstall, isInstalled } = useInstallPrompt();
	const [isVisible, setVisible] = useState(false);
	const hide = () => setVisible(false);

	if (loading) {
		return <Loading />;
	}

	return (
		<AppContext.Provider value={{ globalState, dispatch }}>
			<SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
				<ThemeProvider theme={theme}>
					<ApolloProvider client={POSClient as any}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<SocketContextProvider>
								<Router history={history}>
									<ADEWrapper>
										<Layout>
											<Suspense fallback={<Loading />}>
												<Routes />

												<Backdrop open={isUpdateAvailable}>
													<Dialog
														open={isUpdateAvailable}
														// onClose={() => setReloadDia(false)}
														aria-labelledby="alert-dialog-title"
														aria-describedby="alert-dialog-description"
													>
														<DialogTitle id="alert-dialog-title">
															{'New Update Available'}
														</DialogTitle>
														<DialogContent>
															<DialogContentText id="alert-dialog-description">
																Please update MenuX to the latest version to
																continue.
															</DialogContentText>
														</DialogContent>
														<DialogActions>
															<Button onClick={updateAssets} autoFocus>
																Update
															</Button>
														</DialogActions>
													</Dialog>
												</Backdrop>

												<SnackBarMsg
													open={openSnackBar}
													setOpen={setOpenSnackBar}
													message={snackBarMsg}
												/>
											</Suspense>
										</Layout>
									</ADEWrapper>
									{promptable && !isInstalled ? (
										<InstallBanner
											ButtonOption={{
												section: {
													props: {
														style: { display: !isVisible ? 'none' : null },
													},
												},
												option1: {
													props: {
														onClick: () => hide(),
													},
												},
												option2: {
													props: {
														onClick: () => promptToInstall(),
													},
												},
											}}
										/>
									) : null}
								</Router>
							</SocketContextProvider>
						</MuiPickersUtilsProvider>
					</ApolloProvider>
				</ThemeProvider>
			</SnackBarContext.Provider>
		</AppContext.Provider>
	);
};
export default App;
